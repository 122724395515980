'use client';

import ErrorText from '@/components/Typography/ErrorText';
import clsx from 'clsx';
import * as E from 'fp-ts/Either';
import { pipe } from 'fp-ts/function';
import { useTranslations } from 'next-intl';
import Link from 'next/link';
import { SubmitHandler, useForm } from 'react-hook-form';
import { authWithPassword } from '../actions';
import { useLoginHooks } from '../hooks';

type FormValues = {
  usernameOrEmail: string;
  password: string;
};

const UsernamePasswordForm = () => {
  const t = useTranslations('auth.LoginPage.UsernamePasswordForm');
  const { register, handleSubmit, setError, formState: { errors, isSubmitting } } = useForm<FormValues>();
  const { afterLogin } = useLoginHooks();

  const signinWithPassword: SubmitHandler<FormValues> = async (form) => {
    const authResult = await authWithPassword(form.usernameOrEmail, form.password);
    pipe(
      authResult,
      E.match(
        (e) => setError('root.serverError', { type: 'server', message: e }),
        () => afterLogin(),
      ),
    );
  };

  return (
    <form onSubmit={handleSubmit(signinWithPassword)}>
      <label className="label">
        <span className="label-text text-base-content">{t('username-or-email')}</span>
      </label>
      <input
        className={clsx('input input-bordered w-full', { 'input-error': errors.usernameOrEmail })}
        type="text"
        {...register('usernameOrEmail', { required: t('error.missing-username') })}
      />
      <label className="label">
        <span className="label-text-alt text-error">
          {errors.usernameOrEmail?.message as string}
        </span>
      </label>

      <div className="mb-4">
        <label className="label">
          <span className="label-text text-base-content">{t('password')}</span>
        </label>
        <input
          className={clsx('input input-bordered w-full', { 'input-error': errors.password })}
          type="password"
          {...register('password', { required: t('error.missing-password') })}
        />
        <label className="label">
          <span className="label-text-alt text-error">
            {errors.password?.message as string}
          </span>
        </label>
      </div>

      <Link href="/auth/forgot-password" className="label-text-alt">
        <span className="link text-sm inline-block hover:text-primary hover:underline hover:cursor-pointer transition duration-200">
          {t('forgot-password?')}
        </span>
      </Link>
      <ErrorText className="mt-8">{errors.root?.serverError.message}</ErrorText>
      <button type="submit" className="btn mt-2 w-full btn-primary">
        {isSubmitting && <span className="loading loadding-spinner" />}
        {t('login')}
      </button>
      <div className="text-center mt-4">
        {t('have-not-account')}{' '}
        <Link href="/auth/register">
          <span className="link inline-block hover:text-primary hover:underline hover:cursor-pointer transition duration-200">
            {t('register-now')}
          </span>
        </Link>
      </div>
    </form>
  );
};

export default UsernamePasswordForm;

/**
 * Helper to initialize the PocketBase client, and helper functions.
 */
import { AssetTag } from '@/types/asset';
import { ShowcaseTemplate } from '@/types/showcase-template';
import PocketBase, { Admin, Record } from 'pocketbase';
import { initializePocketBase } from './pocketbase-global';

const pocketBaseUrl = process.env.NEXT_PUBLIC_NOBASE_URL;

let pocketbase = initializePocketBase(pocketBaseUrl || 'https://nb.penless.ai');

export type User = Record | Admin;

export const getRecordFileUrl = (
  pocketbase: PocketBase,
  fileFieldName: string,
) =>
(record: Record) => pocketbase.files.getUrl(record, record[fileFieldName]);

export const getAssetFileUrl = (pocketbase: PocketBase) => getRecordFileUrl(pocketbase, 'file');
export const getAvatarUrl = getRecordFileUrl(pocketbase, 'avatar');

export const getAssetImageUrl = async (
  pocketbase: PocketBase,
  assetId: string,
) => {
  const assetImageRecord = await pocketbase
    .collection('assets')
    .getOne(assetId, {
      $autoCancel: false,
    });
  return getAssetFileUrl(pocketbase)(assetImageRecord);
};

export type UploadAssetParams = {
  name?: string;
  file: File | Blob | string;
  owner?: string;
  tags: AssetTag;
  metadata?: {};
};

export const uploadImageAsset = async (
  pocketbase: PocketBase,
  { name, file, owner, tags, metadata }: UploadAssetParams,
) => {
  const formData = new FormData();
  // If file is string
  if (file && typeof file === 'string') {
    if (file.indexOf('http') == 0) {
      // Remote file
      let fileBuffer = await fetch(file).then(res => res.arrayBuffer());
      formData.append('file', new Blob([fileBuffer]));
    } else {
      // DataURL
      let fileContent = file;
      if (file.indexOf('data:image') == 0) {
        fileContent = file.split(',')[1];
      }
      // Transfer the DataURL to Buffer
      let fileBuffer = Buffer.from(fileContent, 'base64');
      formData.append('file', new Blob([fileBuffer]));
    }
  } else {
    formData.append('file', file);
  }
  name && formData.append('name', name);
  owner && formData.append('owner', owner);
  formData.append('tags', tags);
  if (metadata) {
    formData.append('metadata', JSON.stringify(metadata));
  }
  return pocketbase
    .collection('assets')
    .create(formData, { $autoCancel: false });
};

export const uploadAsset = async (
  pocketbase: PocketBase,
  type: 'audio' | 'video' | 'image' | 'knowledge' | 'model' | 'assistant',
  { name, file, owner, tags, metadata }: UploadAssetParams,
) => {
  const formData = new FormData();
  // If file is string
  if (file && typeof file === 'string') {
    if (file.indexOf('http') == 0) {
      // Remote file
      let fileBuffer = await fetch(file).then(res => res.arrayBuffer());
      formData.append('file', new Blob([fileBuffer]));
    } else {
      // DataURL
      let fileContent = file;
      const dataDesc = `data:${type}`;
      if (file.indexOf(dataDesc) == 0) {
        fileContent = file.split(',')[1];
      }
      // Transfer the DataURL to Buffer
      let fileBuffer = Buffer.from(fileContent, 'base64');
      formData.append('file', new Blob([fileBuffer]));
    }
  } else {
    formData.append('file', file);
  }
  name && formData.append('name', name);
  owner && formData.append('owner', owner);
  formData.append('tags', tags);
  if (metadata) {
    formData.append('metadata', JSON.stringify(metadata));
  }
  return pocketbase
    .collection('assets')
    .create(formData, { $autoCancel: false });
};

export const updateAsset = async (
  pocketbase: PocketBase,
  asset: Record,
): Promise<Record> => {
  return await pocketbase.collection('assets').update(asset.id, asset);
};

export const getShowcaseTemplates = async (pocketbase: PocketBase) => {
  let showcaseTemplates = await pocketbase
    .collection('showcase_templates')
    .getFullList({
      filter: 'enabled=true',
      sort: '-created',
    });
  return showcaseTemplates.map(x => ({
    title: x.template,
    prompt: x.prompt,
    file: getAssetFileUrl(pocketbase)(x),
    id: x.id,
    enabled: x.enabled,
  }));
};

export const uploadShowcaseTemplate = async (
  pocketbase: PocketBase,
  template: ShowcaseTemplate,
) => {
  const formData = new FormData();
  // If file is string
  if (template.file && typeof template.file === 'string') {
    if (template.file.indexOf('http') == 0) {
      // Remote file
      let fileBuffer = await fetch(template.file).then(res => res.arrayBuffer());
      formData.append('file', new Blob([fileBuffer]));
    } else {
      // DataURL
      let fileContent = template.file;
      if (template.file.indexOf('data:image') == 0) {
        fileContent = template.file.split(',')[1];
      }
      // Transfer the DataURL to Buffer
      let fileBuffer = Buffer.from(fileContent, 'base64');
      formData.append('file', new Blob([fileBuffer]));
    }
  } else {
    formData.append('file', template.file);
  }
  formData.append('prompt', template.prompt);
  formData.append('enabled', template.enabled.toString());
  formData.append('template', template.title);
  return pocketbase
    .collection('showcase_templates')
    .create(formData, { $autoCancel: false });
};

/** @see {@link https://github.com/pocketbase/js-sdk/issues/69} */
if (typeof document !== 'undefined') {
  pocketbase.authStore.loadFromCookie(document.cookie);

  pocketbase.authStore.onChange(() => {
    document.cookie = pocketbase.authStore.exportToCookie({ httpOnly: false });
  });
}

// Incrementally update the messages state, to avoid unnecessary re-renders
export const elegantUpdate = (
  records: Record[],
  newRecords: Record[],
  updateFunc: (records: Record[]) => void,
) => {
  // Quick path to avoid performance issue
  if (newRecords.length === 0) {
    updateFunc([]);
    return;
  } else if (records.length === 0) {
    updateFunc(newRecords);
    return;
  }

  // Use a map for efficient lookups by some unique identifier (like 'id')
  const recordMap = new Map(records.map(r => [r.id, r]));

  // Process new messages and update the local state incrementally
  const updatedRecords = newRecords.map(newRecord => {
    const oldRecord = recordMap.get(newRecord.id);
    // If the message hasn't changed, return the old message to avoid re-rendering
    if (oldRecord && JSON.stringify(oldRecord) === JSON.stringify(newRecord)) {
      return oldRecord;
    }
    // Otherwise, use the new record
    return newRecord;
  });

  // Update the state with the incremental records
  updateFunc(updatedRecords);
};

export default pocketbase;

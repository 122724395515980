import pocketbase from '@/utils/pocketbase';
import { useSearchParams } from 'next/navigation';

const DEFAULT_HOME = '/';

export const useLoginHooks = () => {
  const searchParams = useSearchParams();

  const afterLogin = () => {
    pocketbase.authStore.loadFromCookie(document.cookie);
    // TODO: fix this
    // https://github.com/vercel/next.js/issues/42556
    // redirect(searchParams.get('redirect') || DEFAULT_HOME);
    window.location.replace(searchParams.get('redirect') || DEFAULT_HOME);
  };

  return { afterLogin };
};

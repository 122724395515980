import { ReactNode } from 'react';

type Props = {
  className?: string;
  children: ReactNode;
};

const ErrorText = ({ className, children }: Props) => {
  return <p className={`text-center  text-error ${className}`}>{children}</p>;
};

export default ErrorText;

'use client';

import * as Sentry from '@sentry/nextjs';
import { useTranslations } from 'next-intl';
import Image from 'next/image';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { authWithOAuth2Code } from '../actions';
import { useLoginHooks } from '../hooks';
import { OAuth2Provider } from '../types';

// Adapted from: https://github.com/pocketbase/js-sdk/blob/master/src/services/RecordService.ts
// put the authentication at server side, and pass it to client via cookie
// so that the server can access the user info
import pb from '@/utils/pocketbase';
import { ClientResponseError, RealtimeService } from 'pocketbase';

const handleAuthWithOAuth2 = async (
  providerName: OAuth2Provider,
) => {
  const collection = pb.collection('users');

  const authMethods = await collection.listAuthMethods();

  const provider = authMethods.authProviders.find((p) => p.name === providerName);
  if (!provider) {
    throw new ClientResponseError(
      new Error(`Missing or invalid provider "${providerName}".`),
    );
  }

  let redirectUrl = collection.client.buildUrl('/api/oauth2-redirect');
  if (provider.name === 'wechat') {
    // 微信回调域名需要可备案，因此经过 cn 域名中转
    redirectUrl = 'https://nb.penless.ai/api/oauth2-redirect';
  }

  const realtime = new RealtimeService(pb);

  return new Promise<void>(async (resolve, reject) => {
    try {
      // initialize a one-off @oauth2 realtime subscription
      const unsubscribe = await realtime.subscribe(
        '@oauth2',
        async (e) => {
          const oldState = realtime.clientId;

          try {
            unsubscribe();

            if (!e.state || oldState !== e.state) {
              throw new Error('State parameters don\'t match.');
            }

            await authWithOAuth2Code({
              provider: provider.name as OAuth2Provider,
              code: e.code,
              codeVerifier: provider.codeVerifier,
              redirectUrl,
            });

            resolve();
          } catch (err) {
            reject(new ClientResponseError(err));
          }
        },
      );

      const url = new URL(provider.authUrl);
      url.searchParams.set('redirect_uri', redirectUrl);
      url.searchParams.set('state', realtime.clientId);

      _defaultUrlCallback(url.toString());
    } catch (err) {
      realtime.unsubscribe();
      reject(new ClientResponseError(err));
    }
  });
};

const _defaultUrlCallback = (url: string) => {
  if (typeof window === 'undefined' || !window?.open) {
    throw new ClientResponseError(
      new Error(
        `Not in a browser context - please pass a custom urlCallback function.`,
      ),
    );
  }

  let width = 1024;
  let height = 768;

  let windowWidth = window.innerWidth;
  let windowHeight = window.innerHeight;

  // normalize window size
  width = width > windowWidth ? windowWidth : width;
  height = height > windowHeight ? windowHeight : height;

  let left = windowWidth / 2 - width / 2;
  let top = windowHeight / 2 - height / 2;

  const popupWindow = window.open(
    url,
    'oauth2-popup',
    'width='
      + width
      + ',height='
      + height
      + ',top='
      + top
      + ',left='
      + left
      + ',resizable,menubar=no',
  );

  if (popupWindow) {
    const checkPopupClosed = setInterval(() => {
      if (popupWindow.closed) {
        console.log('oauth window closed');
        clearInterval(checkPopupClosed);
      }
    }, 100);
  }
};

type Props = {
  providers: OAuth2Provider[];
};

const OAuth2Form = ({ providers }: Props) => {
  const t = useTranslations('auth.LoginPage.OAuth2Form');
  const [selectedProvider, setSelectedProvider] = useState('');

  const [isOAuthing, setIsOAuthing] = useState(false);
  const { afterLogin } = useLoginHooks();

  return (
    <div className="flex flex-col gap-2">
      {providers.map((provider, index) => (
        <button
          key={index}
          className="btn w-full"
          onClick={() => {
            if (selectedProvider) return; // Avoid multiple clicks
            setSelectedProvider(provider);
            setIsOAuthing(true);
            handleAuthWithOAuth2(provider)
              .then(() => afterLogin())
              .catch((err) => {
                setSelectedProvider('');
                Sentry.captureException(err);
                toast.error((err as Error).message);
              }).finally(() => {
                setSelectedProvider('');
                setIsOAuthing(false);
              });
          }}
        >
          {selectedProvider === provider && isOAuthing
            ? <span className="loading loadding-spinner" />
            : (
              <Image
                priority
                alt={provider}
                width={24}
                height={24}
                src={`/logos/${provider}.svg`}
              />
            )}
          <div className="w-48">{t(`oauth2-${provider}`)}</div>
        </button>
      ))}
    </div>
  );
};

export default OAuth2Form;
